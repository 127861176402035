<template>
  <div class="login">
    <div class="content">
      <span class="lh-icon-logo"></span>
      <lh-input v-model="account" width="100%" icon="lh-icon-user" placeholder="请输入账号"/>
      <lh-input v-model="password" width="100%" icon="lh-icon-password" type="password" placeholder="请输入6位数字密码"/>
      <lh-button @click="login" width="80%" backgroundColor="black">登录</lh-button>
    </div>
  </div>
</template>

<script>
export default {
  name:"Login",
  data(){
    return{
      account:"",
      password:""
    }
  },
  methods:{
    login:function(){
      if(this.account=="lh"&&this.password=="lh"){
        sessionStorage.setItem("user","lh");
        this.$router.push({path:"/"});
      }else{
        this.$lhMessage("error","账号密码错误");
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login{
  width: 100%;
  height: 100vh;
  //border: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  .content{
    width:25%;
    height: 60%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow:0 3px 8px rgba(0,0,0,0.2);
    .lh-icon-logo{
      font-size: 100px;
    }
  }
}
</style>